import {CSSProperties, useEffect, useState} from 'react';
import {updateStateAttribute} from '../../helpers/helper';

interface BodyStructureProps {
  children: React.ReactNode;
  footerHeight: number;
}

interface State {
  minHeight: CSSProperties['minHeight'];
}

export default function BodyStructure(props: BodyStructureProps) {
  const [state, setState] = useState<State>({minHeight: 0});

  useEffect(() => {
    const recomputeMinHeight = () => {
      updateStateAttribute<State>({minHeight: window.innerHeight - props.footerHeight}, setState);
    };

    recomputeMinHeight();
    window.addEventListener('resize', recomputeMinHeight);
    window.addEventListener('scroll', recomputeMinHeight);

    return (() => {
      window.removeEventListener('resize', recomputeMinHeight);
      window.removeEventListener('scroll', recomputeMinHeight);
    })
  }, [props.footerHeight]);

  return (
    <div className={'w-full flex flex-col'} style={{minHeight: state.minHeight}}>
      {props.children}
    </div>
  );
}
