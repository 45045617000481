import axios, {AxiosError} from 'axios';

type APIResponseModel<T> = { data?: T, error?: string };

export default class BaseAPIService {
  protected static async post<T>(uri: string, body?: Record<string, any>, params?: Record<string, string>) {
    try {
      return ((await axios.post<T>(uri, body, {headers: {'x-api-key': 'mraGJOGwaC7VanbXn7fuO7mu30WWHg4d4qPxxIVd'}, params})).data as APIResponseModel<T>);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        return {error: (e as AxiosError).message}
      }
      return {error: 'An unknown error occurred'};
    }
  };
}
