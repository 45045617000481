import React, {CSSProperties} from 'react';

interface PageTemplateProps {
  children: React.ReactNode;
  style?: Pick<CSSProperties, 'paddingTop' | 'paddingRight' | 'paddingLeft' | 'paddingBottom' | 'maxWidth'>
}

export default function PageTemplate(props: PageTemplateProps) {
  return (
    <div className={'p-8 lg:py-16 m-auto flex flex-col gap-8 flex-grow w-full'} style={{maxWidth: 968, ...props.style}}>
      {props.children}
    </div>
  );
}
