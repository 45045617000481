/**
 * Helper function to update an attribute in the component's state.
 * @param partialState
 * @param setStateCallback
 */
export function updateStateAttribute<T>(
  partialState: Partial<T>,
  setStateCallback: (st: (s: T) => T & Partial<T>) => void
) {
  setStateCallback((s) => ({ ...s, ...partialState }));
}
