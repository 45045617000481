import React from 'react';
import './App.css';
import LandingPage from './components/functional/LandingPage';
import Footer from './components/functional/Footer';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import PrivacyPage from './components/functional/PrivacyPage';
import BodyStructure from './components/layout/BodyStructure';
import Header from './components/functional/Header';
import ContactPage from './components/functional/ContactPage';

function App() {
  const location = useLocation();
  const footerHeight = 100;

  return (
    <>
      <BodyStructure footerHeight={footerHeight}>
        <Header pathName={location.pathname}/>
        <Routes>
          <Route path={'/'} element={
            <LandingPage/>
          }/>
          <Route path={'/contact'} element={
            <ContactPage/>
          }/>
          <Route path={'/privacy'} element={
            <PrivacyPage/>
          }/>
          <Route path={'*'} element={<Navigate to={'/'}/>}/>
        </Routes>
      </BodyStructure>
      <Footer style={{height: footerHeight}}
              pathName={location.pathname} />
    </>
  );
}

export default App;
