import {TextField} from '@mui/material';
import {useState} from 'react';
import {updateStateAttribute} from '../../helpers/helper';
import NewsletterService from '../../services/NewsletterService';
import Footer from './Footer';

interface State {
  email: string;
  errorMessage?: string;
  registrationSuccessful: boolean;
}

export default function LandingPage() {
  const [state, setState] = useState<State>({
    email: '',
    registrationSuccessful: false});

  const submitEmail = async () => {
    const res = await NewsletterService.signUpForNewsletter({email: state.email});
    updateStateAttribute<State>({errorMessage: res.error, registrationSuccessful: !res.error}, setState);
  };

  return (
    <div className={'flex flex-col justify-center items-center flex-grow text-white relative gradient-bg'}>
      <div className={'flex flex-col md:flex-row gap-3 gap-y-1 p-8 py-16'}>
        <div className={'flex-shrink-0'}>
          <a href={'/'}>
            <img src={'outlined_1.png'} className={'w-16'}/>
          </a>
        </div>
        <div className={'flex gap-8'} style={{maxWidth: 568}}>
          <div className={'flex flex-col gap-4'}>
            <h1 className={'text-3xl mt-1'}>
              <a href={''}>
                <span className={'text-white'} style={{fontWeight: 800}}>Bumper</span>
                <span className={'text-breeze-500'} style={{fontWeight: 800}}>Buddy</span>
              </a>
              {' '}&mdash; the motor accident reporting app for Singapore is underway!</h1>
            <p>
              Our goal is to digitize the motor accident reporting process to make the experience more stress-free, efficient, and reliable. We are hard at work building an app all motorists in Singapore can confidently use. Exciting times are ahead, and we can't wait to share them with you!
            </p>
            <div className={'border-t border-white opacity-50'}/>
            {
              !state.registrationSuccessful &&
              <>

                <p className={'font-bold'}>
                  Sign up for our newsletter to get updated on our progress and when BumperBuddy becomes available to you!
                </p>
                <div className={'flex flex-col gap-2'}>
                  <div className={'flex flex-col md:flex-row gap-4'}>
                    <TextField label={'Email address'}
                               className={'flex-grow'}
                               value={state.email}
                               error={!!state.errorMessage}
                               onChange={(e) => updateStateAttribute<State>({email: e.target.value, errorMessage: undefined}, setState)} />
                    <button className={'bg-breeze-500 text-white font-bold flex items-center p-4 px-8 rounded-md justify-center'}
                            disabled={state.email === ''}
                            onClick={() => submitEmail()}>
                      Sign Up
                    </button>
                  </div>
                  <p className={'text-red-400 text-sm ml-3'}>{state.errorMessage}</p>
                </div>
                <p className={'text-xs opacity-80'}>By clicking on the "Sign Up" button, I agree to receiving news and promotional materials from and about BumperBuddy by email to the address I am submitting. I consent to BumperBuddy and service providers processing my personal data for these purposes and as described in the <a className={'text-white'} href={'/privacy'}>Privacy Policy</a>. I understand that I can withdraw my consent at any time.</p>
              </>
            }
            {
              state.registrationSuccessful &&
              <>
                <p><b>Thanks for signing up!</b> We will continue working to make BumperBuddy available to you soon &mdash; and when it's ready you'll be the first to know!</p>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
