import {CSSProperties} from 'react';

interface FooterProps {
  style: Pick<CSSProperties, 'height'>
  pathName: string;
}

export default function Footer(props: FooterProps) {
  const isLandingPage = props.pathName === '/';
  return (
    <div className={'flex flex-wrap items-center justify-center px-8 text-sm overflow-hidden text-roya-'}
         style={{
           minHeight: props.style.height,
           backgroundColor: isLandingPage ? 'rgb(9, 42, 61)' : 'none',
           color: isLandingPage ? 'rgb(255, 255, 255)' : 'inherit'}}>
      <div className={'flex flex-wrap gap-4 gap-y-2 justify-center'}>
        <a href={'/contact'} style={{color: isLandingPage ? 'rgb(255, 255, 255)' : 'auto'}}>Contact</a>
        <a href={'/privacy'} style={{color: isLandingPage ? 'rgb(255, 255, 255)' : 'auto'}}>Privacy Policy</a>
        <p>&copy; {new Date().getFullYear()} BumperBuddy Pte. Ltd.</p>
      </div>
    </div>
  );
}
