import PageTemplate from '../layout/PageTemplate';

interface HeaderProps {
  pathName: string;
}

export default function Header(props: HeaderProps) {
  return (
    <>
      {
        props.pathName !== '/' &&
        <div className={'w-full flex flex-col p-2 bg-royal-700'}>
          <PageTemplate style={{paddingBottom: 0, paddingTop: 0}}>
            <a href={'/'} className={'flex gap-2 items-center justify-center'}>
              <img src={'outlined_1.png'} className={'h-7'}/>
              <p className={'text-2xl'} style={{fontWeight: 800}}>
                <span className={'text-white'}>Bumper</span>
                <span className={'text-breeze-500'}>Buddy</span>
              </p>
            </a>
          </PageTemplate>
        </div>
      }
    </>
  );
}
