import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito',
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: '#ffffff',
          borderWidth: '0',
          borderRadius: '0.375rem',
          overflow: 'hidden',
          '&.Mui-disabled': {
            background: '#ffffff'
          },
          '&.Mui-focused': {
            background: '#ffffff'
          },
          '&:hover': {
            background: '#ffffff'
          },
          '& .MuiSelect-select:focus': {
            background: '#ffffff'
          },
          '&::before': {
            border: 'none !important'
          },
          '&::after': {
            borderColor: 'inherit',
            borderWidth: '1px',
            borderRadius: '0.375rem',
            width: '100%',
            height: '100%',
            transform: 'none',
            transition: 'all ease 300ms'
          },
          '&.Mui-focused::after': {
            borderColor: 'rgb(98, 201, 243)'
          },
          '&.Mui-error::after': {
            borderColor: 'rgb(220, 38, 38)',
            borderWidth: '2px'
          }
        }
      }
    }
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
