import PageTemplate from '../layout/PageTemplate';

export default function ContactPage() {
  return (
    <PageTemplate>
      <h1>Get in touch</h1>
      <p>
        Thank you for your interest in BumperBuddy.
        As we prepare for our launch, we are looking at various ways how we can best make ourselves available to you in a reliable and accessible manner.
      </p>
      <p>
        In the meantime, please feel free to contact us via email to <a href={'mailto:hello@bumperbuddy.sg'}>hello@bumperbuddy.sg</a>.
      </p>
    </PageTemplate>
  );
}
